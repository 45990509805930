var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',{staticClass:"p-[11px]"},[_c('div',{staticClass:"text-black text-2xl font-semibold mb-1"},[_vm._v(" Ubah Device ")]),_c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nama Device "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nama Device","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Masukkan Nama Device"},model:{value:(_vm.deviceName),callback:function ($$v) {_vm.deviceName=$$v},expression:"deviceName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Spesifikasi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Spesifikasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Masukkan Spesifikasi"},model:{value:(_vm.specification),callback:function ($$v) {_vm.specification=$$v},expression:"specification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tipe "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tipe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"min-width":"150px"},attrs:{"options":_vm.listType.slice(1),"placeholder":"Ketuk untuk memilih tipe device","reduce":function (data) { return data.value; }},model:{value:(_vm.deviceType),callback:function ($$v) {_vm.deviceType=$$v},expression:"deviceType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Partner "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Partner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.no_partner; },"options":_vm.listPartners,"placeholder":"Ketik untuk mencari..."},on:{"search":function (search) { return search.length > 0 ? _vm.onSearch(search) : ''; }},model:{value:(_vm.noPartner),callback:function ($$v) {_vm.noPartner=$$v},expression:"noPartner"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tanggal diterima "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tanggal diterima","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                        altInput: true,
                        altFormat: 'j F Y',
                        dateFormat: 'Y-m-d',
                      },"placeholder":"Pilih Tanggal"},model:{value:(_vm.devicePickDate),callback:function ($$v) {_vm.devicePickDate=$$v},expression:"devicePickDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-cols-md":"4","label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Attachment ")]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex gap-10"},[_c('div',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"name":"Attachment","rules":"ext:jpg,png,jpeg,pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-file',{staticClass:"w-100",attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.initialFile
                              ? _vm.initialFile.split('/').pop()
                              : "Pilih atau drop file disini","drop-placeholder":"Pilih atau drop file disini","accept":".jpg, image/jpeg, image/png,"},on:{"change":function($event){return _vm.uploadFile($event)}},model:{value:(_vm.fileSelected),callback:function ($$v) {_vm.fileSelected=$$v},expression:"fileSelected"}}),(!errors[0])?_c('small',[_vm._v("*Upload dengan format png, jpg, pdf")]):_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])],1)],1)],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"device-preview d-flex items-center justify-center relative"},[((_vm.fileSelected && _vm.fileExtension !== 'pdf') || _vm.initialFile)?_c('img',{staticClass:"image-preview",attrs:{"src":_vm.fileSelected ? _vm.fileUrl(_vm.fileSelected) : _vm.initialFile,"alt":"preview"}}):_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/image.svg","alt":"no-image"}}),((_vm.fileSelected && _vm.fileExtension !== 'pdf') || _vm.initialFile)?_c('div',{staticClass:"absolute __overlay items-center justify-center"},[_c('button',{staticClass:"d-flex justify-center items-center bg-gray-50 rounded px-2 py-[10px] gap-10",on:{"click":function($event){return _vm.$bvModal.show('modalPreview')}}},[_c('span',{staticClass:"k-eye"}),_vm._v(" Lihat Foto ")])]):_vm._e()])])],1),_c('div',{staticClass:"d-flex justify-content-end mt-3"},[_c('button',{staticClass:"mr-50 button-secondary",attrs:{"type":"kembali"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"mr-50 button-primary w-[200px]",attrs:{"disabled":invalid || _vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)])]}}])})],1),_c('ModalPreview',{attrs:{"image-url":_vm.fileSelected ? _vm.fileUrl(_vm.fileSelected) : _vm.initialFile ? _vm.initialFile : null}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }